import React, { useEffect } from 'react'
import './notfound.css'

const NotFound = () => { 
    useEffect(() => {
      document.title = "404";
    }, []);
  return (
    <>
    <div className='notfound d-flex justify-content-center align-items-center flex-column'>
<span className='text-center text-white' style={{fontSize: '7rem'}}>404</span>
<p className='text-center text-white' style={{fontSize: '5rem'}}>Page Not Found</p>
<p className='text-center text-white' style={{fontSize: '2rem'}}>We think you just want to a page non-existing page.
<br/>
Please Navigate back to other tabs or pages.</p>
    </div>
    
    
    </>
  )
}

export default NotFound;