import React from 'react'
import { useEffect } from 'react'
import './event1.css'
import pharmaConnect from './eventImg/pharmaConnect.jpeg'
import pharmaConnect2 from './eventImg/pharmaconnect 2.jpeg'
import pharmaConnectwebp1 from './eventImg/Pharma Connect_NEW-1.webp'
import pharmaConnectwebp2 from './eventImg/Pharma Connect_NEW-2.webp'
import pharmaConnectwebp3 from './eventImg/Pharma Connect_NEW-3.webp'
import pharmaConnectwebp4 from './eventImg/Pharma Connect_NEW-4.webp'
import pharmaConnectwebp5 from './eventImg/Pharma Connect_NEW-5.webp'
import pharmaConnectwebp6 from './eventImg/Pharma Connect_NEW-6.webp'
import pharmaConnectwebp7 from './eventImg/Pharma Connect_NEW-7.webp'
import pharmaConnectwebp8 from './eventImg/Pharma Connect_NEW-8.webp'
import pharmaConnectwebp9 from './eventImg/Pharma Connect_NEW-9.webp'


const Event1 = () => {
  useEffect(() => {
    document.title = "Pharma Connect";
  }, []);
  return (
<>
<div className='eventheading'>

      <h1  className='text-center'>17th January 2025</h1>
      <h3 className='text-center'>Pharma Connect</h3>
      </div>

    <div className='container' id='calanderevent'>
    <img src={pharmaConnect} alt='pharmaConnect'/>
<button className='mb-4'><a href='https://ishraehq.in/Admin/CalenderEventMaster/Registration_new/ZFbkslseEafobksls5I=' target='_blank'>Register Now </a></button>
    <img src={pharmaConnect2} alt='pharmaConnect'/>

    <img src={pharmaConnectwebp1} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp2} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp3} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp4} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp5} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp6} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp7} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp8} alt='pharmaConnect'/>
    <img src={pharmaConnectwebp9} alt='pharmaConnect'/>

    </div>
</>
  )
}

export default Event1